<template>
  <div>
    <div class="blueDetailItem">
      <div class="detailHeadTitle">
        {{ headTitle }}
      </div>
      <div class="detailTitle" :style="{color:color}">
        {{ contentTitle }}
      </div>
    </div>
    <div style="width: 95%;height: 1px;background-color: lightsteelblue;opacity: 0.5;"></div>
  </div>
</template>

<script>
export default {
  name: "blueDetailItem",
  props: {
    color:{
      default:'#000000'
    },
    headTitle: {
      default: ''
    },
    contentTitle: {
      default: ''
    },
  },
}
</script>

<style lang="scss" scoped>

.blueDetailItem {
  width: 90%;
  height: 100%;
  display: flex;
  transform: translate(5.555%, 0px);


  .detailHeadTitle {
    width: 70px;
    height: 44px;
    line-height: 44px;
    text-align: left;
  }

  .detailTitle {
    line-height: 44px;
    text-align: right;
    word-wrap: break-word;
    width: calc(100% - 70px);
  }
}

</style>
