<template>
  <div class="inPutBGView">
    <div class="inPut">
      <div class="inPutHeadTitle">
        {{ headTitle }}
      </div>
      <input type="text" class="inPutTitle" v-model="content" :placeholder="contentHodler">
    </div>
    <div style="width: 95%;height: 1px;background-color: lightsteelblue;opacity: 0.5;"></div>
    <div class="sureButton" @click="saveData">
      保存
    </div>
  </div>
</template>

<script>
export default {
  name: 'inPut',
  props: {
    inPutInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      content: '',
      match: '.',
      headTitle: 'title',
      contentTitle: '',
      contentHodler: '请输入内容',
      matchType: '',
      dataKey: 'data',
      inPutKey: 'setDataInfo'
    }
  },
  watch: {
    inPutInfo: {
      handler(value) {
        for (const key in value) {
          this[key] = value[key];
        }
      },
      immediate: true,
      deep: true
    },
    matchType: {
      handler(value) {
        if (value == 'all') {
          this.match = '.'
        } else if (value == 'onlyNum') {
          this.match = '(-?[0-9]+\\.?[0-9]?)?[0-9]$'
        } else if (value == 'onlyChar') {
          this.match = '^[A-Za-z]+$'
        } else if (value == 'onlyUUID') {
          this.match = '^[A-Za-z0-9]+$'
        } else if (value == 'onlyInt') {
          this.match = '^[0-9]+$'
        } else if (value == 'onlyIP') {
          this.match = '((2(5[0-5]|[0-4]\\d))|[0-1]?\\d{1,2})(\\.((2(5[0-5]|[0-4]\\d))|[0-1]?\\d{1,2})){3}'
        } else if (value == 'onlyBool') {
          this.match = '^[0-1]$'
        }
      },
      immediate: true,
      deep: true
    },
    contentTitle: {
      handler(value) {
        this.content = value
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  computed: {},
  methods: {
    errorMsg: function() {
      let msg = '╰(￣▽￣)╭'
      if (this.matchType == 'all') {
        msg = '请输入地球语言 (￣▽￣)~*'
      } else if (this.matchType == 'onlyNum') {
        msg = '请输入数字,可以包含小数点 (￣▽￣)"'
      } else if (this.matchType == 'onlyChar') {
        msg = '请输入纯字母 <(￣︶￣)>'
      } else if (this.matchType == 'onlyUUID') {
        msg = '请输入数字字母 []~(￣▽￣)~*'
      } else if (this.matchType == 'onlyInt') {
        msg = '请输入纯数字 (￣３￣)a'
      } else if (this.matchType == 'onlyIP') {
        msg = '请输入正确的IP (･ェ･。)'
      } else if (this.matchType == 'onlyBool') {
        msg = '请输入0或者1 ヾ(=･ω･=)o'
      }
      return msg
    },
    saveData: function() {
      let matchTransMatrix = new RegExp(this.match, 'gmi')
      let rep = this.content.match(matchTransMatrix)
      if (rep == null) {
        this.$message({
          message: this.errorMsg(),
          type: 'warning'
        })
        return
      }

      let info = {}
      info[this.dataKey] = this.content
      this.$emit('updateInfo', info)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.inPutBGView {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  background-color: white;

  .inPut {
    display: flex;
    width: 90%;
    transform: translate(5.555%, 0px);

    .inPutHeadTitle {
      width: 70px;
      height: 44px;
      line-height: 44px;
      text-align: left;
    }

    .inPutTitle {
      line-height: 44px;
      text-align: right;
      word-wrap: break-word;

      width: calc(100% - 70px);
      border: 0;
      outline: none;
    }
  }

  .sureButton {
    background-color: $color_primary;
    border-radius: 10px;
    width: 80%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    transform: translate(12.5%, 0px);
    color: white;
    margin-top: 10px;
  }
}

</style>
