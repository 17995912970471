<template>
  <div class="blueToothDetail">
    <ul class="detailUl">
      <li class="detailLi">
        <blue-detail-item :head-title="'mac'" :content-title="blue.address"></blue-detail-item>
      </li>
      <li class="detailLi">
        <blue-detail-item :head-title="'rssi'" :content-title="blue.rssi"></blue-detail-item>
      </li>
      <li class="detailLi" @click="pushInPut('onlyUUID', 'UUID', blue.uuid, 'uuid')">
        <blue-detail-item :head-title="'UUID'" :content-title="blue.uuid"></blue-detail-item>
      </li>
      <li class="detailLi" @click="pushInPut('onlyInt', 'major', blue.major, 'major')">
        <blue-detail-item :head-title="'major'" :content-title="blue.major"></blue-detail-item>
      </li>
      <li class="detailLi" @click="pushInPut('onlyInt', 'minor', blue.minor, 'minor')">
        <blue-detail-item :head-title="'minor'" :content-title="blue.minor"></blue-detail-item>
      </li>
      <li class="detailLi" @click="pushInPut('onlyInt', '测量功率', blue.testPower, 'testPower')">
        <blue-detail-item :head-title="'测量功率'" :content-title="blue.testPower"></blue-detail-item>
      </li>
      <li class="detailLi" @click="pushInPut('onlyInt', '发射功率', blue.transPower, 'transPower')">
        <blue-detail-item :head-title="'发射功率'" :content-title="blue.transPower"></blue-detail-item>
      </li>
      <li class="detailLi" @click="pushInPut('onlyInt', '发射间隔', blue.broadCastInterval, 'broadCastInterval')">
        <blue-detail-item :head-title="'发射间隔'" :content-title="blue.broadCastInterval"></blue-detail-item>
      </li>
      <li class="detailLi">
        <blue-detail-item :head-title="'连接状态'" :content-title="statusMsg" :color="statusColor"></blue-detail-item>
      </li>
    </ul>


    <div class="connectButton" @click.stop="startConnent">
      <div class="connectBox">
        <div class="connectButtonTitle">
          保存
        </div>
        <div class="connectLoadingBox">
          <div v-if="showConnentAnimation" class="spinner">
            <div class="spinner-container container1">
              <div class="circle1"></div>
              <div class="circle2"></div>
              <div class="circle3"></div>
              <div class="circle4"></div>
            </div>
            <div class="spinner-container container2">
              <div class="circle1"></div>
              <div class="circle2"></div>
              <div class="circle3"></div>
              <div class="circle4"></div>
            </div>
            <div class="spinner-container container3">
              <div class="circle1"></div>
              <div class="circle2"></div>
              <div class="circle3"></div>
              <div class="circle4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <in-put v-if="showInPut" @updateInfo="updateInfo" :in-put-info="inPutInfo"></in-put>

  </div>
</template>

<script>
import BlueDetailItem from '@/views/blueTooth/blueToothDetail/blueDetailItem/blueDetailItem'
import InPut from '@/components/inPut/inPut'

export default {
  name: 'blueToothDetail',
  components: { InPut, BlueDetailItem },
  computed: {
    showConnentAnimation: function() {
      if (this.connectStatus == 'connecting') {
        return true
      }
      return false
    },
    statusColor: function() {
      if (this.connectStatus == 'update_info_success' ||
          this.connectStatus == 'update_uuid_success' ||
          this.connectStatus == 'verify_pw_success' ||
          this.connectStatus == 'init_read_success' ||
          this.connectStatus == 'init_write_success' ||
          this.connectStatus == 'connect_success') {
        return 'springgreen'
      } else if (this.connectStatus == 'device_not_found' ||
          this.connectStatus == 'connect_fail' ||
          this.connectStatus == 'un_connect_device' ||
          this.connectStatus == 'device_info_null' ||
          this.connectStatus == 'transform_to_beacon_error' ||
          this.connectStatus == 'uuid_form_error' ||
          this.connectStatus == 'major_range_error' ||
          this.connectStatus == 'minor_range_error' ||
          this.connectStatus == 'minor_range_error' ||
          this.connectStatus == 'broadCastInterval_range_error' ||
          this.connectStatus == 'testPower_range_error' ||
          this.connectStatus == 'transPower_range_error') {
        return 'red'
      } else if (this.connectStatus == 'no') {
        return 'black'
      } else {
        return 'blue'
      }
    },
    statusMsg: function() {
      let msg = ''
      switch (this.connectStatus) {
        case 'device_not_found':
          msg = '找不到设备'
          break
        case 'connect_fail':
          msg = '连接失败'
          break
        case 'connect_success':
          msg = '连接成功'
          break
        case 'init_write_success':
          msg = '初始化写入成功'
          break
        case 'init_read_success':
          msg = '初始化读取成功'
          break
        case 'un_connect_device':
          msg = '未连接设备'
          break
        case 'device_info_null':
          msg = '更新设备信息为空'
          break
        case 'transform_to_beacon_error':
          msg = '转换为Beacon失败'
          break
        case 'uuid_form_error':
          msg = 'uuid格式错误'
          break
        case 'major_range_error':
          msg = 'major格式错误'
          break
        case 'minor_range_error':
          msg = 'minor格式错误'
          break
        case 'broadCastInterval_range_error':
          msg = '广播间隔格式错误'
          break
        case 'testPower_range_error':
          msg = '测试功率格式错误'
          break
        case 'transPower_range_error':
          msg = '传输功率格式错误'
          break
        case 'send_pw':
          msg = '向设备发送密码'
          break
        case 'send_uuid':
          msg = '向设备发送uuid'
          break
        case 'send_info':
          msg = '向设备发送详细信息'
          break
        case 'verify_pw_success':
          msg = '校验密码成功'
          break
        case 'update_uuid_success':
          msg = '更新uuid成功'
          break
        case 'update_info_success':
          msg = '更新详细信息成功'
          break
        case 'no':
          msg = '无连接'
          break
        case 'connecting':
          msg = '连接中'
          break
        default:
          break
      }
      return msg
    }
  },
  data() {
    return {
      showInPut:false,
      inPutInfo:{},
      updateBlueCount: 0,
      updateBlueTotalCount: 40,
      couldWriteBeacon: true,
      connectStatus: 'no',
      blue: {
        rssi: 0,
        uuid: '',
        address: '',
        major: 0,
        minor: 0,
        transPower: 0,
        testPower: 0,
        broadCastInterval: 0
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      for (const key in this.$route.query) {
        this.blue[key] = this.$route.query[key]
      }
      this.blueStatus()
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'login') {
      next(true)
      return;
    }
    if (this.showInPut) {
      this.showInPut = false;
      next(false);
      return;
    }
    next(true);
  },
  beforeDestroy() {
    this.closeBluetooth()
  },
  watch: {
    'connectStatus': {
      handler(value) {
        if (this.shouldWrite) {
          this.couldWriteBeacon = true
        }
        if (value == 'verify_pw_success') {
          this.wirteBT()
        }
        if (value == 'update_info_success') {
          setTimeout(() => {
            this.updateBeaconSuccess()
          }, 500)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    updateInfo:function(blueEditor) {
      for (const key in blueEditor) {
        this.blue[key] = blueEditor[key];
      }
      this.showInPut = false;
    },
    pushInPut: function(matchType, headTitle, content, dataKey) {
      this.showInPut = true;
      this.inPutInfo = {
        headTitle: headTitle,
        contentTitle: content,
        matchType:matchType,
        dataKey:dataKey,
        inPutKey:'setBlueEditor',
      }
    },
    openBluetooth: function() {
      setTimeout(() => {
        this.flutter.openBluetooth()
      }, 500)
    },
    closeBluetooth: function() {
      this.flutter.closeBluetooth()
      this.flutter.disconnectBT()
    },
    updateBeaconSuccess: function() {
      this.flutter.disconnectBT()
      this.couldWriteBeacon = true
      this.connectStatus = 'no'
      this.openBluetooth();
    },
    blueStatus: function() {
      this.openBluetooth()
      this.flutter.onBluetoothDevices((list) => {
        if (this.updateBlueCount < this.updateBlueTotalCount) {
          this.updateBlueCount += 1
          return
        }
        this.updateBlueCount = 0
        for (let i = 0; i < list.length; i++) {
          let beacon = list[i]
          if (beacon.address == this.blue.address) {
            this.blue.rssi = beacon.rssi
            break
          }
        }
      })
      this.flutter.onConnectStatus((result) => {
        this.connectStatus = result
      })
    },
    shouldConnent: function() {
      if (this.connectStatus == 'device_not_found' ||
          this.connectStatus == 'connect_fail' ||
          this.connectStatus == 'un_connect_device' ||
          this.connectStatus == 'device_info_null' ||
          this.connectStatus == 'no') {
        return true
      }
      return false
    },
    shouldWrite: function() {
      if (this.connectStatus == 'update_info_success' ||
          this.connectStatus == 'transPower_range_error' ||
          this.connectStatus == 'testPower_range_error' ||
          this.connectStatus == 'broadCastInterval_range_error' ||
          this.connectStatus == 'minor_range_error' ||
          this.connectStatus == 'major_range_error' ||
          this.connectStatus == 'uuid_form_error' ||
          this.connectStatus == 'transform_to_beacon_error' ||
          this.connectStatus == 'device_info_null' ||
          this.connectStatus == 'un_connect_device' ||
          this.connectStatus == 'connect_fail' ||
          this.connectStatus == 'device_not_found') {
        return true
      }
      return false
    },
    startConnent: function() {
      if (this.blue.address == '') {
        return
      }
      if (this.shouldConnent()) {
        this.connectBT()
      } else if (this.shouldWrite()) {
        this.wirteBT()
      }
    },
    connectBT: function() {
      this.connectStatus = 'connecting'
      this.flutter.connectBT(this.blue.address)
    },
    wirteBT: function() {
      if (this.couldWriteBeacon == false) {
        return
      }
      this.couldWriteBeacon = false
      setTimeout(() => {
        let beaconStr = '{' +
            '"uuid": "' + this.blue.uuid + '",' +
            '"major":' + this.blue.major + ',' +
            '"minor": ' + this.blue.minor + ',' +
            '"broadCastInterval": ' + this.blue.broadCastInterval + ',' +
            '"testPower":' + this.blue.testPower + ',' +
            '"transPower": ' + this.blue.transPower +
            '}'
        this.flutter.updateBT(beaconStr)
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.blueToothDetail {
  width: 100%;
  height: 100%;


  .detailUl {
    padding: 0;
    list-style: none;

    .detailLi {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .connectButton {
    width: 80%;
    height: 40px;
    background-color: $color_primary;
    border-radius: 7px;
    transform: translate(12.5%, 0px);
    display: flex;
    justify-content: center;

    .connectBox {
      width: 110px;
      height: 40px;
      display: flex;
      transform: translate(25px, 0px);

      .connectButtonTitle {
        color: white;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        width: 60px;
        text-align: center;
      }

      .connectLoadingBox {
        width: 50px;
        height: 100%;

        .spinner {
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 10px;
          width: 20px;
          height: 20px;
          position: relative;
        }

        .container1 > div, .container2 > div, .container3 > div {
          width: 6px;
          height: 6px;
          background-color: #fff;

          border-radius: 100%;
          position: absolute;
          -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
          animation: bouncedelay 1.2s infinite ease-in-out;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }

        .spinner .spinner-container {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .container2 {
          -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
        }

        .container3 {
          -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
        }

        .circle1 {
          top: 0;
          left: 0;
        }

        .circle2 {
          top: 0;
          right: 0;
        }

        .circle3 {
          right: 0;
          bottom: 0;
        }

        .circle4 {
          left: 0;
          bottom: 0;
        }

        .container2 .circle1 {
          -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
        }

        .container3 .circle1 {
          -webkit-animation-delay: -1.0s;
          animation-delay: -1.0s;
        }

        .container1 .circle2 {
          -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
        }

        .container2 .circle2 {
          -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
        }

        .container3 .circle2 {
          -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
        }

        .container1 .circle3 {
          -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
        }

        .container2 .circle3 {
          -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
        }

        .container3 .circle3 {
          -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
        }

        .container1 .circle4 {
          -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
        }

        .container2 .circle4 {
          -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
        }

        .container3 .circle4 {
          -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
        }

        @-webkit-keyframes bouncedelay {
          0%, 80%, 100% {
            -webkit-transform: scale(0.0)
          }
          40% {
            -webkit-transform: scale(1.0)
          }
        }

        @keyframes bouncedelay {
          0%, 80%, 100% {
            transform: scale(0.0);
            -webkit-transform: scale(0.0);
          }
          40% {
            transform: scale(1.0);
            -webkit-transform: scale(1.0);
          }
        }
      }

    }


  }
}

</style>
